/*!
 * Theme: AniModernHTML
 *
 * @author XSites Team
 */

/**
 * Globals
 */
html {
    overflow-y: scroll;

    body {
        font-size: 8pt;
        font-family: Verdana, Arial;
        background-color: #333333;
        color: #000000;
    }
}

/**
 * Embed fix
 */
.embed-wrapper {
    position: relative;
    z-index: 0;
}

/**
 * Phrasings
 */
h1 {
    margin-top: 0;
}
sup {
    font-size: 0.5em;
}

/**
 * Navbars
 */
.navbar {
    border: none;
}
.navbar-nav {
    margin: 0;
}

/**
 * Scaffolding
 */
.container {
    padding: 0 7px;
    width: auto;

    &.xs-main-container {
        background: #FFF;
        display: table;
        width: 768px;

        > header[class],
        > main[class] {
            margin: 0;
        }
    }
}

/**
 * Page contents
 */
.xs-dynamic-content-manager {
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 600;
    }
}
#XSCallToAction {
    margin-bottom: 10px;
}

/* Header Styles */
@import 'header';

/* Navbar (Primary nav) Styles */
@import 'navbar';

/* Main Content Styles */
@import 'main';

/* Sidebar Styles */
@import 'sidebar';

/* Footer Styles */
@import 'footer';
