/**
 * Sidebar styles
 */
.xs-sidebar {   
    height: auto;
    padding: 0;
    vertical-align: top;
    white-space: nowrap;

    &.show {
        height: auto;
    }

    .nav.navbar-nav {
        float: none;
    }
}

.xs-profile-picture {
    display: none;
    padding: 19px 19px 7px 19px;

    img {
        height: auto;
        max-width: 100%;
        border: 1px solid black;
    }
}


#xs-sidebar {
    margin-right: 5px;
    max-width: 160px;
    min-width: 160px;
    padding: 0;
    width: 160px;

    li {
        float: none;
        display: block;
        position: relative;

        &.spacer {
            background: transparent;
            height: 24px;
            border: none;
        }

        /* indented items */
        &.indent > a {
            padding-left: 20px;
        }

        /* has sub-nav child */
        &.has-children {
            svg.sub-indicator {
                display: none;
            }

            &:hover > a:after {
                border-left-color: #000;
            }

            > a {
                position: relative;

                &:after {
                    border: solid transparent;
                    border-color: transparent;
                    border-left-color: #FFF;
                    border-width: 4px;
                    content: " ";
                    height: 0;
                    margin-top: -5px;
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    width: 0;
                }
            }
        }

        /* 'fly-out' sub-navigation */
        .sub-nav {
            background: transparent;
            display: none;
            left: 100%;
            margin: 0;
            padding: 0;
            position: absolute;
            top: 0;
            width: 150px;
            z-index: 101;

            &,
            & li,
            & a {
                width: auto;
                max-width: none;
            }

            li {
                a {
                    padding: 6px;

                    &:hover {
                        background: #FFF;
                        color: #000;
                        text-decoration: none;
                    }
                }

                &.indent a {
                    padding-left: 12px;
                }
            }
        }

        /* on li:hover
        SHOW .sub-nav */
        &:hover {
            > a {
                background: #FFF;
                color: #000;
            }

            .sub-nav {
                display: block;
            }
        }

        a {
            display: block;
            max-width: 160px;
            min-width: 160px;
            padding: 2px 10px;
            width: 160px;

            svg {
                margin: 0 6px 0 0;
            }
        }
    }
}

#xs-sidebar .xs-language-options {
    background: rgba(255, 255, 255, 0.65);
    border: 1px solid rgba(black,0.5);
    color: #000;
    font-size: 11px;
    margin: 5px 8px;
    padding: 0.5em 0;

    label {
        margin-bottom: 0;
    }

    a {
        color: #0000FF;
        font-size: 9px;
        font-style: normal;
        line-height: 13.7143px;
        text-align: center;
        text-decoration: underline;

        &::after {
            color: #000;
            content: "|";
            display: inline-block;
            margin: 0 1px 0 2px;
            text-decoration: none;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }
}

.xs-alt-navigation {
    li {
        background-color: transparent;
        margin-bottom: 1em;
        
        a {
            background-color: transparent;
            background-position: center;
            background-repeat: no-repeat;
            min-height: 31px;
            text-indent: -999em;
            
            /* "Tell a Friend */
            &[href="/TellaFriend"], &[href="/tellfriend.x"] {
                background-image: url(/images/tellafriend.png);
            }
            
            /* "Adobe Acrobat */
            &[href="/DownloadAdobeAcrobat"], &[href="/downloadadobe.x"] {
                background-image: url(/images/acrobat.png);
            }
        }
    }
}
