/**
 * Header styles
 */
header {
    background-color: #fff;
    margin: 0;
    max-width: 755px;
    overflow: hidden;
    padding: 25px 0 0;
    position: relative;

    &:after,
    &:before {
        content: '';
        height: 25px;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
    }
    &:after {
        background: url('/graphics/AniModernHTML/animodern-stripes.png') no-repeat;
        z-index: 2
    }

    nav.navbar {
        min-height: 0;
    }

    .header-image {
        height: 120px;
        margin: 7px 0;

        > * {
            border-color: #FFF;
            border-style: solid;
            border-width: 0 3px 0 0;
            height: 120px;
            float: left;
            overflow: hidden;
            padding: 0;
            width: 380px;

            & ~ * {
                border-width: 0 0 0 3px;
                width: 374px;
            }
        }

        .company-details {
            position: relative;
            top: -30px;
        }
    }

    .theme-background {
        background-size: 100%;
    }

    .company-logo {
        position: absolute;
        display: none;
       
        img {
            height: auto;
            width: auto;
        }
    }

    .company-name {
        line-height: 1;
        position: absolute;
        text-decoration: none;
        
        &:hover {
            text-decoration: none;
        }
    }

    .company-motto {
        line-height: 1;
        position: absolute;
    }
}
